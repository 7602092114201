import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vda_login = _resolveComponent("vda-login")!

  return (_openBlock(), _createBlock(_component_vda_login, {
    onOnError: _ctx.onError,
    onOnConnected: _ctx.onConnected,
    contextName: _ctx.contextName,
    logo: _ctx.logo,
    loginText: _ctx.loginText
  }, null, 8, ["onOnError", "onOnConnected", "contextName", "logo", "loginText"]))
}